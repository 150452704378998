<template>
  <fw-layout :footer="showFooter" extended-logo mobile-ready :back-to-enable="false">
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="centered-container">
        <fw-panel boxed="xl">
          <div class="flex flex-row gap-10 md:px-5 md:py-2">
            <div class="lg:w-[22rem] flex justify-center">
              <div class="w-[22rem]">
                <fw-heading size="h2" class="mb-5 sm:mb-8">{{ $t('title.ucAccount') }}</fw-heading>
                <form method="post" @submit.prevent="doLogin">
                  <b-field
                    :label="isInstitutionalEmail ? 'Email institucional' : 'Email'"
                    label-for="email"
                    :type="{ 'is-danger': $v.email.$error }"
                    :message="$v.email.$error ? $t('message.form.emailRequired') : ''"
                  >
                    <b-input v-model="email" type="email" @input="checkEmail"></b-input>
                  </b-field>
                  <b-field
                    label="Password"
                    label-for="password"
                    :type="{ 'is-danger': $v.password.$error }"
                    :message="$v.password.$error ? $t('message.form.passwordRequired') : ''"
                  >
                    <b-input v-model="password" type="password" name="password" />
                  </b-field>

                  <!-- Login errors -->
                  <div v-if="loginError" class="my-5 text-base">
                    <div class="font-bold text-sm mb-1 text-red-800">Ocorreu um erro:</div>
                    <div v-if="loginError" class="p-3 bg-red-50 rounded-lg mb-5 text-red-800 font-medium">
                      <div class="max-w-sm mx-auto text-center">
                        <div v-if="loginError == 'MaxAttempts'">
                          <div>Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.</div>
                        </div>
                        <div v-else-if="loginError == 'InvalidEmail'">
                          <div>O email indicado não parece ser válido.</div>
                        </div>
                        <div v-else-if="loginError == 'InvalidUCEmailOrPassword'">
                          <div>O email ou password não estão corretos ou a conta não existe.</div>
                        </div>
                        <div v-else-if="loginError == 'EmailNotActivated'">
                          <div>
                            <div>O email indicado corresponde a uma conta que ainda não foi ativada.</div>
                            <div class="text-sm mt-3">
                              Procure o email de validação que enviámos no momento do registo, na sua Inbox ou mesmo na
                              pasta SPAM.
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div>Não foi possível autenticar.</div>
                          <div class="text-sm font-bold p-2">Error: {{ loginError }}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="emailResent || (loginError && loginError == 'EmailNotActivated')"
                    class="mt-4 text-center font-medium text-gray-500 text-sm"
                  >
                    <div v-if="!emailResent && !errorFound">
                      <fw-label marginless>{{ $t('message.noEmailArrived') }}?</fw-label>
                      <fw-button type="link-light" size="sm" @click.native="resendEmail">{{
                        $t('button.sendValidationEmailAgain')
                      }}</fw-button>
                    </div>
                    <div v-else-if="emailResent" class="font-bold text-primary p-2">
                      A mensagem de validação de conta foi enviada novamente.
                    </div>
                  </div>

                  <b-field class="mt-4">
                    <b-button
                      expanded
                      :disabled="!canLogin"
                      :type="canLogin && !loginError ? 'is-primary' : 'is-dark'"
                      size="is-large"
                      @click="doLogin"
                      >{{ $t('button.login') }}</b-button
                    >
                  </b-field>
                  <b-field>
                    <b-checkbox v-model="keepSignIn" size="is-small">
                      <span class="text-sm text-gray-500 font-medium">{{ $t('label.keepActiveSession') }}</span>
                    </b-checkbox>
                  </b-field>
                </form>

                <div v-if="allowExternalAccountsLogin" class="mt-5 text-gray-500">
                  <router-link class="text-sm font-semibold" :to="{ name: 'external-account-recover-password' }">{{
                    $t('link.recoverPassword')
                  }}</router-link>
                </div>

                <div v-if="showAnonymousJoin" class="has-margin-top-large">
                  <div class="has-text-small has-text-muted has-text-centered">
                    {{ $t('title.doNotHaveUCAccount') }}
                  </div>
                  <div class="has-margin-top">
                    <b-button
                      type="is-light"
                      expanded
                      @click="$router.push({ name: 'anonymous', params: { key: redirectToRoom } })"
                      >{{ $t('button.enterAsGuest') }}</b-button
                    >
                  </div>
                </div>

                <div v-if="allowExternalAccountsRegister && !showAnonymousJoin" class="mt-14">
                  <div
                    v-if="!showAnonymousJoin"
                    class="text-center font-semibold text-gray-700 text-md max-w-xs mx-auto"
                  >
                    {{ $t('title.doNotHaveUCAccount') }}
                  </div>
                  <div class="mt-4">
                    <b-button type="is-light" expanded @click="$router.push({ name: 'external-account-signup' })">{{
                      $t('button.registerAccount')
                    }}</b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="allowPasswordless" class="flex justify-center md:border-l md:ml-4 md:pl-12">
              <BlockPasswordlessLogin
                :links="true"
                :show-branding="true"
                :qrcode-size="170"
                :show-instructions="true"
              />
            </div>
          </div>
        </fw-panel>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import debounce from 'lodash/debounce'

import BlockPasswordlessLogin from '@/fw-modules/fw-core-vue/id/components/blocks/BlockPasswordlessLogin'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import utilsId from '../utils'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  name: 'Login',

  components: {
    BlockPasswordlessLogin,
    HeaderLanguagePanel
  },

  data() {
    return {
      email: '',
      password: '',
      keepSignIn: false,
      emailResent: null,
      loginError: '',
      errorFound: '',
      isEmailNotAllowed: false,
      supportEmail: 'support@ucframework.pt',
      showAnonymousJoin: process.env.VUE_APP_KEY == 'ucmeetings',
      institutionalDomains: utilsId.institutionalDomainsString(),
      debugMode: Boolean(localStorage.getItem('fw-debug')),
      redirectToRoom: false,
      countDown: 240
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(1) },
    keepSignIn: false
  },

  computed: {
    allowExternalAccountsRegister() {
      return FwEnvConfig.allowExternalAccountsRegister
    },
    allowExternalAccountsLogin() {
      return FwEnvConfig.allowExternalAccountsLogin
    },
    isAllowedEmail() {
      return this.allowExternalAccountsRegister || this.allowExternalAccountsLogin || this.isInstitutionalEmail
    },
    canLogin() {
      return true
    },
    isInstitutionalEmail() {
      if (this.email) return utilsId.isInstitutionalEmail(this.email)
      return false
    },
    isExternalInstitutionalEmail() {
      if (this.email) return utilsId.isExternalInstitutionalEmail(this.email)
      return false
    },
    isMobile() {
      return utils.isMobile()
    },
    allowPasswordless() {
      return !this.isMobile && !this.isExternalApp
    },
    showFooter() {
      return this.$store.state.appName !== 'ucdigitaldesk'
    },
    isExternalApp() {
      return this.$store.state.appName == 'mondaecus'
    }
  },

  created() {
    this.email = this.email
    this.checkEmail = debounce(() => {
      this.isEmailNotAllowed = !this.isAllowedEmail
    }, 1500)

    // Parse redirect (if found)
    const query = this.$route.query
    if (query && query.redirect && query.redirect.startsWith('/live/')) {
      const redirectParts = query.redirect.split('/')
      this.redirectToRoom = redirectParts.length ? redirectParts.slice(-1)[0] : false
    }
  },

  methods: {
    doLogin() {
      this.$v.$touch()

      this.emailResent = null
      this.errorFound = null

      if (!this.$v.$invalid) {
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password,
            keepSignIn: this.keepSignIn
          })
          .then(response => {
            this.loginError = false
            let redirect = this.$route.query.redirect || '/'
            if (redirect.includes('logout')) redirect = '/'

            this.$router.push(redirect)
            return response
          })
          .catch(error => {
            this.loginError = utils.errors(error).getKey() || 'Undefined'
            console.warn(`login error: ${this.loginError}`, error)
          })
      }
    },

    async resendEmail() {
      this.errorFound = false
      let result = false

      try {
        result = await ServiceAuth.resendConfirmationEmail(this.email, process.env.VUE_APP_KEY)
      } catch (error) {
        this.errorFound = utils.errors(error).getKey() || 'Undefined'
        console.warn(`login error: ${this.errorFound}`, error)

        this.$buefy.snackbar.open({
          message: this.$t('error.tooManyAttempts'),
          type: 'is-danger',
          position: 'is-top-right',
          duration: 2000,
          queue: false
        })
        return
      }
      this.emailResent = result
    }
  }
}
</script>

<i18n>
{
  "en": {
    "title": {
      "authentication": "Authentication",
      "ucAccount": "Sign in with UC Account",
      "doNotHaveUCAccount": "Don't have a UC account?"
    },
    "button": {
      "login": "Login",
      "registerAccount": "Sign up",
      "enterAsGuest": "Enter as guest"
    },
    "label": {
      "keepActiveSession": "Keep me logged in"
    },
    "link": {
      "recoverPassword": "Recover password"
    },
    "message": {
      "toRecover": "Use <a href={link}>{link}</a> to recover the password of account <b>{{ email }}</b>.<br />",
      "validatingAccount": "Validating account...",
      "confirmedAccount": "Confirmed account",
      "checkEmail": "Check mailbox",
      "confirmEmailSent": "An email has been sent to you to confirm your account.",
      "resendConfirmEmail": "Resend confirmation email",
      "createAccountNotNeeded": "You don't need to create an account for",
      "notAllowedEmail": "To enter, use your institutional {application} account at the University of Coimbra",
      "form": {
        "passwordConfirmation": "Confirm your password",
        "passwordConfirmationPlaceholder": "Password confirmation",
        "passwordRequired": "Paswword is required",
        "passwordSameAs": "Password confirmation must be same as password",
        "emailRequired": "Email is required"
      }
    },
    "login": {
      "setPassword": "Enter password.",
      "invalidPassword": "Wrong email or password.",
      "open": "Signin",
      "openApplication": "Signin",
      "register": "Signup",
      "recover": "Reset account",
      "signout": "Signout",
      "keepSession": "Keep session alive",
      "security": "If you're on a public device, please use a incognito/private browser window and signout from your account when done.",
      "pwdForgot": "Forgot password?",
      "authFailed": "Not possible to signin. Please, try again later.",
      "maxAttempts": "Maximum number of attempts reached. Please, try again later.",
      "emailNotRegistered": "It looks like your e-mail address is not registered.",
      "toSignin": "To signin,",
      "goRegisterPage": "go to the registration page",
      "doRegister": "and create a new account (in case you don't have an UC account) or link your institutional address."
    },
    "signup": {
      "label": "Register account",
      "resetCode": "Reset code is invalid.",
      "maxAttemptsReached": "Maximum nunber of attempts reached.",
      "emailregistered": "The provided e-mail address is already registered. Please provide a different e-mail address to continue.",
      "difficulties": "In case of trouble signing in, please try to ",
      "recoverPassword": "reset the password",
      "contactSupport": "or create a support request.",
      "pwdMinChars": "Your password needs a minimum of 8 characters.",
      "pwdMinLetters": "Your password needs a minimum of 3 letters.",
      "registrationNotPossible": "Account not registered.",
      "registrationSuccessful": "Your account has been successfully registered!",
      "validateAccount": "Please check your e-mail account to validate your e-mail address.",
      "checkSpam": "If you're havinf trouble finding our message, please, check your SPAM folder.",
      "finishSignin": "Please, signin to finish your registration.",
      "institutionalAccounts": "Para contas institucionais, a operação de registo confirma apenas a sua intenção em aceder à plataforma.",
      "institutionalSignin": "Após submeter o seu registo, deverá entrar na aplicação e utilizar o email",
      "institutionalSigninPwd": "e password da sua conta institucional.",
      "institutionalNotAvailable": "O seu endereço pertence a um grupo de domínios institucional entre os quais esta plataforma não consegue garantir a autenticação.<br />Assim, para entrar, por favor, defina uma password e valide a sua conta através da mensagem de confirmação que iremos enviar após registo.",
      "pwdValid": "Provide a valid password",
      "pwdMatch": "Passwords don't match.",
      "pwdSafe": "Provide a safe password, with a minimum of 8 characters, 3 of them being letters.",
      "pwdConfirm": "Confirm password",
      "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
      "backToSignin": "back to signin page",
      "error": "There was an error processing your registration"
    }
  },
  "pt": {
    "title": {
      "authentication": "Autenticação",
      "ucAccount": "Entrar com conta UC",
      "doNotHaveUCAccount": "Não tem conta institucional UC?"
    },
    "button": {
      "login": "Entrar",
      "registerAccount": "Registar conta",
      "enterAsGuest": "Entrar como convidado"
    },
    "label": {
      "keepActiveSession": "Manter sessão ativa"
    },
    "link": {
      "recoverPassword": "Recuperar password"
    },
    "message": {
      "toRecover": "Utilize <a href={link}>{link}</a> para recuperar a palavra-passe do e-mail <b>{{ email }}</b>.<br />",
      "validatingAccount": "A validar conta...",
      "confirmedAccount": "Conta validada",
      "checkEmail": "Verifique o seu e-mail",
      "confirmEmailSent": "Foi enviado um e-mail para confirmar a sua conta.",
      "resendConfirmEmail": "Reenviar e-mail de confirmação",
      "createAccountNotNeeded": "Não precisa de criar conta para",
      "notAllowedEmail": "Para entrar em {application}, utilize a sua conta institucional da Universidade de Coimbra",
      "form": {
        "passwordConfirmation": "Confirme a palavra-passe",
        "passwordConfirmationPlaceholder": "Confirmação de palavra-passe",
        "passwordRequired": "A palavra-passe é obrigatória",
        "passwordSameAs": "A confirmação da palavra-passe tem que ser igual",
        "emailRequired": "O email é obrigatório"
      }
    },
    "login": {
      "setPassword": "Introduza a palavra-passe.",
      "invalidPassword": "Email ou palavra-passe incorretos.",
      "open": "Entrar",
      "openApplication": "Entrar na aplicação",
      "register": "Registar",
      "recover": "Recuperar conta",
      "signout": "Sair",
      "keepSession": "Manter sessão ativa",
      "security": "Se está num dispositivo público, por favor, utilize uma janela de navegação privada e saia da sua conta quando terminar.",
      "pwdForgot": "Esqueceu-se da palavra-passe?",
      "authFailed": "Não foi possível autenticar. Por favor, tente mais tarde.",
      "maxAttempts": "Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.",
      "emailNotRegistered": "O email indicado não parece estar registado.",
      "toSignin": "Para se registar,",
      "goRegisterPage": "vá para a página de registo",
      "doRegister": "e crie uma nova conta (caso não tenha uma conta UC) ou associe o seu endereço institucional."
    },
    "signup": {
      "label": "Registar conta",
      "resetCode": "Código de recuperação inválido.",
      "maxAttemptsReached": "Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.",
      "emailregistered": "O email indicado já está registado. Por favor, indique um email diferente para continuar.",
      "difficulties": "Em caso de dificuldades no registo, por favor, tente ",
      "recoverPassword": "recuperar a palavra-passe",
      "contactSupport": "ou crie um pedido de suporte.",
      "pwdMinChars": "A sua palavra-passe precisa de um mínimo de 8 caracteres.",
      "pwdMinLetters": "A sua palavra-passe precisa de um mínimo de 3 letras.",
      "registrationNotPossible": "Conta não registada.",
      "registrationSuccessful": "A sua conta foi registada com sucesso!",
      "validateAccount": "Por favor, verifique o seu e-mail para validar o seu endereço de e-mail.",
      "checkSpam": "Se tiver dificuldades em encontrar a nossa mensagem, por favor, verifique a pasta SPAM.",
      "finishSignin": "Por favor, entre para finalizar o seu registo.",
      "institutionalAccounts": "Para contas institucionais, a operação de registo confirma apenas a sua intenção em aceder à plataforma.",
      "institutionalSignin": "Após submeter o seu registo, deverá entrar na aplicação e utilizar o email",
      "institutionalSigninPwd": "e password da sua conta institucional.",
      "institutionalNotAvailable": "O seu endereço pertence a um grupo de domínios institucional entre os quais a plataforma ainda não consegue garantir a autenticação institucional.<br />Assim, para entrar na plataforma, por favor, defina uma password e valide a sua conta através da mensagem de confirmação que iremos enviar após registo.",
      "pwdValid": "Forneça uma palavra-passe válida",
      "pwdMatch": "As palavras-passe não coincidem.",
      "pwdSafe": "Forneça uma palavra-passe segura, com um mínimo de 8 caracteres, sendo 3 deles letras.",
      "pwdConfirm": "Confirme a palavra-passe",
      "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
      "backToSignin": "voltar à página de entrada",
      "error": "Houve um erro a processar o seu registo"
    }
  }
}
</i18n>
